import React from 'react'
import {Col, Row} from "react-bootstrap";
import AliceCarousel from 'react-alice-carousel';
import teammates from '../../teammates.json'
import Teammate from "./teammate/teammate";

const handleDragStart = (e) => e.preventDefault();
const OurTeam = ({isPersonalState}) => {

    const items = teammates.map((item) => {
        return <Teammate isPersonalState={isPersonalState} data={item} handleDragStart={handleDragStart}/>
    })

    return <div className={'d-flex flex-column w-100 align-items-center pt-5 pb-5'}>
        <Col className={'align-items-start w-100 pt-5 pb-4'}>
            <h2 className={'big-text'}>УЧАСНИКИ</h2>
        </Col>
        <Row className={'w-100'}>
            <AliceCarousel
                responsive={{
                    0: {
                        items: 1,
                    },
                    625: {
                        items: 2,
                    },
                    900: {
                        items: 3,
                    },
                    1300: {
                        items: 4,
                    },
                    1600: {
                        items: 5,
                    },
                    1850: {
                        items: 6,
                    }
                }}
                disableButtonsControls infinite mouseTracking items={items}/>
        </Row>
    </div>
}

export default OurTeam