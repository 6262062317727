import React from 'react'
import {Col, Image, Row} from "react-bootstrap";
import transactionImage from "../../images/transaction.png";

export const LastTransaction = () => {

    return <Col className={'pt-5 pb-5'}>
         <h2 className={'big-text'}>ЗНЯТТЯ З ОСНОВНОЇ БАНКИ</h2>
        <Row className={'pt-4 pb-2 pb-md-5'}>
            <Col>
                <h2 className={'mt-3 mb-3'}>
                    26 квітня з основної банки збору було знято 42 890 грн, ці гроші витратились на термінову купівлю антени Avenger для підсилення зв'язку.
                </h2>
            </Col>
        <Col>
            <Image className={'align-self-center content-photo'} src={transactionImage} />
        </Col>
        </Row>
    </Col>
}