import React from 'react'
import ReactPlayer from "react-player";

export const VideoCollect = ({file}) => {

    return <div className='collect-video-player'>
        <ReactPlayer
            url={`/collects/${file}`}
            className='react-player'
            controls
            width="100%"
            height="auto"
        />
    </div>
}