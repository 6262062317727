import React from 'react'
import {Col, Image, Row} from "react-bootstrap";
import bountyImage from '../../images/bounty.jpg'
import bountySecondImage from '../../images/bounty1.jpg'
import {Link} from "react-alice-carousel";

export const Raffle = () => {
    return <Col>
        <h2 className={'big-text'}>РОЗІГРАШ</h2>
        <Row className={'pt-5 pb-2 pb-md-5'}>
            <Col className={'d-flex justify-content-center'} md={6}>
                <Image className={'align-self-center content-photo'} src={bountyImage} />
            </Col>
            <Col className={'d-flex flex-column'} md={6}>
                <h2 className={'big-text mt-5'}><span>ШАМПАНСЬКЕ З ЗРУЙНОВАНОГО БАХМУТСЬКОГО ВИННОГО ЗАВОДУ</span></h2>
                <h2 className={'py-2'}>Доєднайся до збору як учасник та отримай можливість виграти пляшку шампанського Artwine з бахмутського винного заводу.</h2>
            </Col>
        </Row>
        <Row className={'pt-5 pb-2 pb-md-5'}>
            <Col className={'d-flex flex-column'} md={6}>
                <h2 className={'big-text mt-5'}><span>ЯК ДОЄДНАТИСЬ?</span></h2>
                <h2 className={'py-2'}>Перейди на <Link style={{color: '#0600F6'}} target='_blank'
                                                        href={'https://send.monobank.ua/jar/8n5WmN9CY'}>монобанку</Link>,
                    тицьни значок з плюсиком та введи ціль кратну 1 тисячі гривень.</h2>
                <h2 className={'py-2'}>Також щоб отримати власний креатив для збору <Link style={{color: '#0600F6'}} target='_blank'
                                                                                          href={'https://www.instagram.com/arthur_snoz/'}>напиши мені</Link>.</h2>
            </Col>
            <Col className={'d-flex justify-content-center'} md={6}>
                <Image className={'align-self-center content-photo'} src={bountySecondImage}/>
            </Col>
        </Row>
    </Col>
}