import React from 'react'
import {ImageCollect} from "./image";
import {VideoCollect} from "./video";

const Collect = ({data, handleDragStart}) => {

    const {
        file,
        type,
        name,
        date,
        target
    } = data

    return <div className={'pb-3'}
                style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div onDragStart={handleDragStart} className={'collect-container'}>
            <div className={'collect-info'}>
                <div>
                    <p className={'big-text'}><span>{name}</span></p>
                </div>
                <div>
                    <p><strong>{target} грн.</strong></p>
                    <p style={{marginBottom: '0'}}>{date}</p>
                </div>
            </div>
            {
                type === 'image' && <ImageCollect file={file}/>
            }
            {
                type === 'video' && <VideoCollect file={file} />
            }
        </div>
    </div>
}

export default Collect