import React from 'react'
import {Button} from "react-bootstrap";
import {ExternalLinkIcon} from "../our-team/teammate/teammate";

const Footer = () => {

    return <div className={'footer p-5 w-100 d-flex align-items-center justify-content-center'}>
<div className={'footer-info d-flex align-items-center p-4 flex-wrap justify-content-center'}>
    <p style={{marginBottom: '0', marginRight: '20px'}} className={'big-text'}>Є питання або хочеш приєднатись до команди?</p>
    <a className={'align-self-end'} href={'https://www.instagram.com/arthur_snoz'} target='_blank' >
        <Button  variant="primary" size="lg">
            НАПИШИ МЕНІ
            <ExternalLinkIcon/>
        </Button>
    </a>
</div>
    </div>
}

export default Footer