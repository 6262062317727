import React from 'react'
import {Col} from "react-bootstrap";
import Header from "../../components/header/header";
import {Raffle} from "../../components/raffle/raffle";
import OurCollects from "../../components/our-collects/our-collects";
import Footer from "../../components/footer/footer";
import {LastCollect} from "../../components/last-collect/last-collect";
import {Reason} from "../../components/reason/reason";
import OurTeam from "../../components/our-team/our-team";
import {LastTransaction} from "../../components/last-transaction/last-transaction";

export const SecondQuadroCollect = () => {

    return <div className='d-flex flex-column w-100 align-items-center'>
        <Col className={'p-3 p-md-0'} xs={12} md={10}>
            <Header/>
        </Col>
        <Col className={'p-3 p-md-0'} xs={12} md={10}>
            <OurTeam isPersonalState={false}/>
        </Col>
        <Col className={'p-3 p-md-0'} xs={12} md={10}>
            <LastCollect/>
        </Col>
        <Col className={'p-3 mb-5 p-md-0'} xs={12} md={10}>
            <Reason/>
        </Col>
        <Col className={'p-3 p-md-0'} xs={12} md={10}>
            <LastTransaction/>
        </Col>
        <Col className={'p-3 p-md-0'} xs={12} md={10}>
            <Raffle/>
        </Col>
        <OurCollects/>
        <Footer/>
    </div>
}