import React from 'react'
import {Col, Image, Row} from "react-bootstrap";
import bountyImage from '../../images/last-collect.jpg'

export const LastCollect = () => {
    return <Col>
        <h2 className={'big-text'}>МИНУЛИЙ ЗБІР</h2>
        <Row className={'pt-5 pb-2 pb-md-5'}>
            <Col className={'d-flex justify-content-center'} md={6}>
                <Image className={'align-self-center content-photo'} src={bountyImage} />
            </Col>
            <Col className={'d-flex flex-column'} md={6}>
                <h2 className={'big-text mt-5'}><span>КВАДРОЦИКЛ TGB BLADE 600 LTX EPS</span></h2>
                <h2 className={'py-2'}>Квадрик вже кілька місяців допомогає нашим хлопцям з логістикою в рамках
                    підрозділу. Та вже було проведено перше технічне обслуговування.</h2>
            </Col>
        </Row>
    </Col>
}