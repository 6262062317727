import React from 'react'
import ATVimage from '../../images/ATV.png'
import logoImage from '../../images/logo.png'
import {Button, Col, Image, Row} from "react-bootstrap";
import {ExternalLinkIcon} from "../our-team/teammate/teammate";

const Header = () => {

    return <Col>
        <Row className={'align-items-center mt-sm-5 mt-3'}>
            <Col className={'d-flex flex-column'} md={6}>
                <Image className={'header-logo align-self-center'} src={logoImage} />
                <h1 className={'mt-sm-5 mt-3 big-text'} >КВАДРО ЗБІР 2.0 ДЛЯ <span className={'text-nowrap'}>8 ОГШБ</span></h1>
                <h2><strong className={'custom-shadow'}><span>Груповий збір</span></strong> на додатковий <strong className={'custom-shadow'}><span>квадроцикл</span></strong> для покращення логістики мінометного підрозділу у складі 8 ОГШБ</h2>
                <h2 className={'big-text mt-5'}>ЦІЛЬ <span>300 000 грн.</span></h2>
                <a style={{marginTop: '8px'}} href={'https://letsdonate.org.ua/projects/137'} target='_blank' >
                    <Button  variant="primary" size="lg">
                        СТАТИСТИКА ЗБОРУ
                        <ExternalLinkIcon/>
                    </Button>
                </a>
            </Col>
            <Col md={6}><Image className={'w-100 mt-5 atv-image animate__animated animate__infinite animate__pulse animation-duration-5s'} src={ATVimage} /></Col>
        </Row>
    </Col>
}
export default Header