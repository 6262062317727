import React from 'react'
import {Col, Row} from "react-bootstrap";
import AliceCarousel from "react-alice-carousel";
import Collect from "./collect/collect";

const collectsData = [
    {
        file: '1.jpg',
        type: 'image',
        name: 'DJI MATRICE 30',
        date: '21.11.2022',
        target: '280 000'
    },
    {
        file: '2.jfif',
        type: 'image',
        name: '2 ЗАРЯДНІ СТАНЦІЇ ECOFLOW ROVER PRO ТА АНТЕНА ALIENTECH',
        date: '12.01.2023',
        target: '77 780'
    }, {
        file: '3.jfif',
        type: 'image',
        name: 'ГЕНЕРАТОР ТА РОЗХІДНИКИ',
        date: '15.01.2023',
        target: '31 000'
    }, {
        file: '4.jpg',
        type: 'image',
        name: '6 МАСКУВАЛЬНИХ СІТОК',
        date: '12.05.2023',
        target: '12 000'
    }, {
        file: '5.jpg',
        type: 'image',
        name: '2 АНТЕНИ AVANGER ДЛЯ ПОКРАЩЕННЯ СИГНАЛУ ТА ПРОТИДІЇ РЕБ',
        date: '15.06.2023 - 15.07.2023',
        target: '206 790'
    }, {
        file: '6.jpg',
        type: 'image',
        name: '2 БАНДЕРАПАВЕРИ S225',
        date: '21.09.2023',
        target: '59 036'
    }, {
        file: '7.jpg',
        type: 'image',
        name: 'БАНДЕРАПАВЕР S305',
        date: '16.10.2023',
        target: '36 430'
    }, {
        file: '8.jpg',
        type: 'image',
        name: 'КВАДРОЦИКЛ TGB BLADE 600 LTX EPS',
        date: '11.12.2023',
        target: '350 000'
    },
    {
        file: '9.MOV',
        type: 'video',
        name: 'АНТЕНA AVANGER ДЛЯ ПОКРАЩЕННЯ СИГНАЛУ ТА ПРОТИДІЇ РЕБ',
        date: '26.04.2024',
        target: '93 260'
    },
]
const handleDragStart = (e) => e.preventDefault();


const OurCollects = () => {

    const collects = collectsData.map((item) => {
        return <Collect data={item} handleDragStart={handleDragStart}/>
    })

    return <div className={'d-flex flex-column w-100 align-items-center pt-5 pb-5'}>
        <Col className={'pt-4 pb-4 px-3'} xs={12} md={10}>
            <h2 className={'big-text'}>ВИКОНАНІ ЗАПИТИ</h2>
        </Col>
        <Row className={'w-100 pt-4'}>
            <AliceCarousel
                responsive={{
                    0: {
                        items: 1,
                    },
                    900: {
                        items: 2,
                    },
                    1600: {
                        items: 3,
                    },
                    1850: {
                        items: 4,
                    }
                }}
                disableButtonsControls infinite mouseTracking items={collects}/>
        </Row>
    </div>
}

export default OurCollects