import React from 'react'
import {Button, Image} from "react-bootstrap";
import './style.css'

const Teammate = ({data, handleDragStart, isPersonalState}) => {

    const {
        name,
        photo,
        target,
        url,
        isClosed
    } = data

    return <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div onDragStart={handleDragStart} role="presentation" className='teammate-container mx-5 my-3'>
            <div className='teammate-photo-container'>
                <Image src={`/teammates/${photo}`}/>
            </div>
            <div className={'p-2 d-flex flex-column'}>
                <h3 style={{fontSize: '30px', marginBottom: '0'}} className={'big-text'}>{name}</h3>
                <p>ЦІЛЬ: <strong>{target} ГРН.</strong></p>
                {
                    !isPersonalState && <>
                        {
                            !isClosed && url !== '#' && <a className={'align-self-end'} href={url} target='_blank'>
                                <Button variant="primary" size="lg">
                                    МОНОБАНКА
                                    <ExternalLinkIcon/>
                                </Button>
                            </a>
                        }
                        {
                            isClosed && <Button style={{pointerEvents: 'none'}} variant="primary" size="lg">❤️ ЗІБРАНО 🎉</Button>
                        }
                    </>
                }
            </div>
        </div>
    </div>
}

export default Teammate

export const ExternalLinkIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="18"
                height="18" x="0" y="0" viewBox="0 0 512 512"
                className="mb-2" style={{marginLeft: '8px'}}>
        <g>
            <g data-name="ARROW 48">
                <path
                    d="M487.84 272.85a24 24 0 0 0-24 24v96.61a70.46 70.46 0 0 1-70.38 70.38H118.54a70.46 70.46 0 0 1-70.38-70.38V118.54a70.46 70.46 0 0 1 70.38-70.38h96.61a24 24 0 1 0 0-48h-96.61A118.52 118.52 0 0 0 .16 118.54v274.92a118.52 118.52 0 0 0 118.38 118.38h274.92a118.52 118.52 0 0 0 118.38-118.38v-96.61a24 24 0 0 0-24-24z"
                    fill="#FFFFFF" opacity="1" data-original="#000000" className=""/>
                <path
                    d="M487.33 0h-149.2a24 24 0 0 0-24 23.53C313.88 37 325.21 48 338.67 48H430L238.86 239.2a24 24 0 0 0 0 33.94 24 24 0 0 0 33.94 0L464 82v91.66a24 24 0 0 0 48 0v-149A24.66 24.66 0 0 0 487.33 0z"
                    fill="#FFFFFF" opacity="1" data-original="#000000" className=""/>
            </g>
        </g>
    </svg>
}